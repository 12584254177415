export default [
    /*
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
    action:'read',
    resource: 'home',
    id:'home',
  },*/
    {
        header: 'Dev Tools',
        action: 'read',
        resource: 'buildList',
    },
    {
        id: 'buildList',
        title: 'Build Manager',
        route: 'build-list',
        icon: 'CodeIcon',
        action: 'read',
        resource: 'buildList'
    },
    {
        id: 'build-tools',
        title: 'Build Tools',
        icon: 'BriefcaseIcon',
        action: 'read',
        resource: 'buildErrorAnalysisList||buildServerList||unityEditorVersionList',
        children: [
            {
                id: 'buildServerList',
                title: 'Build Servers',
                route: 'buildServer-list',
                icon: 'ServerIcon',
                action: 'read',
                resource: 'buildServerList'
            },
            {
                id: 'unityEditorVersionList',
                title: 'Unity Editor Versions',
                route: 'unityEditorVersionList-list',
                icon: 'PackageIcon',
                action: 'read',
                resource: 'unityEditorVersionList'
            },
            {
                id: 'buildErrorAnalysisList',
                title: 'Error Analyses',
                route: 'buildErrorAnalysis-list',
                icon: 'AlertCircleIcon',
                action: 'read',
                resource: 'buildErrorAnalysisList'
            },
        ]
    },
    {
        header: 'HR Tools',
        action: 'read',
        resource: 'discordUserList||bitbucketTracker',
    },
    {
        id: 'discordUserList',
        title: 'Discord Tracker',
        icon: 'ClockIcon',
        action: 'read',
        resource: 'discordUserList',
        children: [
            {
                id: 'discordUserList',
                title: 'Team View',
                icon: 'UsersIcon',
                action: 'read',
                resource: 'discordUserList',
                route: 'discord-list',
            },
            {
                id: 'discordUserList',
                title: 'Detail View',
                icon: 'UserIcon',
                action: 'read',
                resource: 'discordUserList',
                route: 'discord-detail',
            },
            {
                id: 'discordUserList',
                title: 'Channel List',
                icon: 'ArchiveIcon',
                action: 'read',
                resource: 'discordUserList',
                route: 'discord-channel-list',
            },
        ]
    },
    {
        id: 'bitbucketTracker',
        title: 'Bitbucket Tracker',
        icon: 'GitBranchIcon',
        action: 'read',
        resource: 'bitbucketTracker',
        children: [
            {
                id: 'bitbucketTracker',
                title: 'Repository View',
                icon: 'GitMergeIcon',
                action: 'read',
                resource: 'bitbucketTracker',
                route: 'bitbucket-repositories',
            },
            {
                id: 'bitbucketTracker',
                title: 'User View',
                icon: 'GitCommitIcon',
                action: 'read',
                resource: 'bitbucketTracker',
                route: 'bitbucket-user-list',
            },
        ]
    },
    {
        id: 'unityTracker',
        title: 'Unity Tracker',
        icon: 'BarChart2Icon',
        action: 'read',
        resource: 'unityTracker',
        children: [
            {
                id: 'unityTracker',
                title: 'Team View',
                icon: 'UsersIcon',
                action: 'read',
                resource: 'unityTracker',
                route: 'unity-list',
            },
            {
                id: 'unityTracker',
                title: 'User View',
                icon: 'UserIcon',
                action: 'read',
                resource: 'bitbucketTracker',
                route: 'unity-detail',
            },
        ]
    },
    {
        header: 'Project Tools',
        action: 'read',
        resource: 'conceptList||appList',
    },
    {
        id: 'concept',
        title: 'Concept',
        icon: 'FileIcon',
        action: 'read',
        resource: 'conceptList',
        children: [
            {
                id: 'conceptList',
                title: 'Concept List',
                icon: 'FileIcon',
                action: 'read',
                resource: 'conceptList',
                route: 'concept-list',
            },
            {
                id: 'conceptCreate',
                title: 'New Concept',
                icon: 'FileIcon',
                action: 'read',
                resource: 'conceptCreate',
                route: 'concept-create',
            }
        ]
    },
    {
        id: 'app',
        title: 'Apps',
        icon: 'FolderIcon',
        action: 'read',
        resource: 'appList',
        children: [
            {
                id: 'appList',
                title: 'App List',
                icon: 'FolderIcon',
                action: 'read',
                resource: 'appList',
                route: 'app-list',
            },
            {
                id: 'storeAccount',
                title: 'Store Accounts',
                icon: 'MenuIcon',
                action: 'read',
                resource: 'appCreate',
                route: 'store-account-list',
            }
        ]
    },

    {
        header: 'Ads Tools',
        action: 'read',
        resource: 'campaignList',
    },
    {
        id: 'campaigns',
        title: 'Lead Campaigns',
        icon: 'LayersIcon',
        action: 'read',
        resource: 'campaignList',
        children: [
            {
                id: 'campaignList',
                title: 'Campaign List',
                icon: 'LayersIcon',
                action: 'read',
                resource: 'campaignList',
                route: 'campaign-list',
            },
            {
                id: 'campaignCreate',
                title: 'New Campaign',
                icon: 'LayersIcon',
                action: 'read',
                resource: 'campaignCreate',
                route: 'campaign-create',
            }
        ]
    },
    {
        id: 'networkAccountList',
        title: 'Network Accounts',
        route: 'network-account-list',
        icon: 'MenuIcon',
        action: 'read',
        resource: 'networkAccountList'
    },

    {
        header: 'Admin Tools',
        action: 'read',
        resource: 'userList||settingList'
    },
    {
        id: 'user',
        title: 'User',
        icon: 'UserIcon',
        children: [
            {
                id: 'userList',
                title: 'User List',
                icon: 'UserIcon',
                action: 'read',
                resource: 'userList',
                route: 'user-list',
            },
            {
                id: 'userRoleList',
                title: 'User Role List',
                icon: 'UserIcon',
                action: 'read',
                resource: 'userRoleList',
                route: 'user-role-list',
            },
            {
                id: 'unpairedUsers',
                title: 'Unpaired Users',
                icon: 'UserXIcon',
                action: 'read',
                resource: 'userList',
                route: 'unpaired-users',
            }
        ]
    },
    {
        id: 'teamList',
        title: 'Team List',
        icon: 'UsersIcon',
        action: 'read',
        resource: 'teamList',
        route: 'team-list',
    },

    {
        id: 'settingList',
        title: 'Settings',
        route: 'setting-list',
        icon: 'SettingsIcon',
        action: 'read',
        resource: 'settingList'
    },
    {
        id: 'activityLogList',
        title: 'Activity Log',
        icon: 'ActivityIcon',
        action: 'read',
        resource: 'activityLogList',
        route: 'activity-log-list',
    },
]
